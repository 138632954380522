export const mountainOverview = [
    {
        mountainId: 1,
        mountainName: "Fansipang",
        mountainImageWidthDesktop: "140px",
        mountainColor: "#FFFF2E",
    },
    {
        mountainId: 2,
        mountainName: "Kinabalu",
        mountainImageWidthDesktop: "220px",
        mountainColor: "#0000FF",
    },
    {
        mountainId: 3,
        mountainName: "Kilimanjaro",
        mountainImageWidthDesktop: "320px",
        mountainColor: "#00FF00",
    },
    {
        mountainId: 4,
        mountainName: "Everest",
        mountainImageWidthDesktop: "460px",
        mountainColor: "#FF0000",
    },
]

export const mountainExplaination = {
    title: "Tổng Quan",
    exaplaination: "Mỗi ngọn núi sẽ đem lại những giá trị khác nhau",
    note: "Nhấp vào từng ngọn núi để xem thêm chi tiết",
    pressingRegister: "Hãy đăng nhập ngay để bắt đầu cuộc hành trình",
    buttonTitle: "Khám Phá Ngay",
}

export const howToDoMountain = {
    title: "Hướng Dẫn",

}
